import { useState } from 'react';

import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { msalConfig } from "./config/authConfig";

import './App.css';

const pca = new PublicClientApplication(msalConfig);

const App = () => {
	const [setImage] = useState({ preview: '', data: '' })
	const [inputList, setInputList] = useState([{ width: "", height: "" }]);

	const handleFileChange = (e) => {
		const img = {
		preview: URL.createObjectURL(e.target.files[0]),
		data: e.target.files[0],
		}
		setImage(img)
	}

	// handle change in inputs
	const handleInputChange = (e, index) => {
		const { name, value } = e.target;
		const list = [...inputList];
		list[index][name] = value;
		setInputList(list);
	};

	// handle click event of the Remove button
	const handleRemoveClick = index => {
		const list = [...inputList];
		list.splice(index, 1);
		setInputList(list);
	};

	// handle click event of the Add button
	const handleAddClick = () => {
		setInputList([...inputList, { width: "", height: "" }]);
	};


	return (
		<div className="App">
			<MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
				<nav className="nav-bar">
					<a href='/' className="nav-brand">
						<object type="image/svg+xml" data="/geo-logo.svg" alt="Geoengineers">
							<img src="/geo-logo.svg" alt="GeoengineersImage" />
						</object>
						<div> 
							Image Resizer
						</div>
					</a>
				</nav>
				<div className="container-body">
					<h1>Upload to Server</h1>
					<p><b>Note:</b> Recommended dimensions for Teams/Outlook photos is <i>450x600</i></p>
					<form method="POST" action="/profile-upload-resize" encType="multipart/form-data">
						<div>
							<label>Upload profile picture &nbsp;</label>
							<input type="file" name="profile-file" required onChange={handleFileChange}/>
											<br/>
						</div>
						<div>
											<br/>
							<label><b>Input Dimensions Width x Height: </b></label>
							{inputList.map((x, i) => {
								return (
									<div key={i}>
										<input name="width" 
										placeholder="Enter Width" 
										value={x.width}
										type="text"
										onChange={e => handleInputChange(e,i)} 
										/>
										<input name="height" 
										placeholder="Enter Height" 
										value={x.height}
										type="text"
										onChange={e => handleInputChange(e,i)} 
										/>
										<div className="btn-align">
											{inputList.length !== 1 && <button
												onClick={() => handleRemoveClick(i)}>Remove</button>}
											{inputList.length - 1 === i && <button onClick={handleAddClick}>Add</button>}
										</div>
									</div>
								)
							})}
							<br/>
							<input type="checkbox" name="preset1" id="preset1" />450x600
							<input type="checkbox" name="preset2" id="preset2" />300x300
							<input type="checkbox" name="preset3" id="preset3" />150x150
						</div>
						<div>
							<input type="submit" value="Upload"/>
						</div>
					</form>
				</div>
				<footer>
					<small>
						© GeoEngineers 2023 | v3.0.10 | Please Contact&nbsp;
						<a href="mailto:softwareengineeringsupport@geoengineers.com">
							softwareengineeringsupport@geoengineers.com</a>
						&nbsp;for support.
					</small>
				</footer>
			</MsalAuthenticationTemplate>
			<UnauthenticatedTemplate>
                  <span>You are not currently authenticated, please login to continue.</span>
			</ UnauthenticatedTemplate>			
		</div>
	);
}

export default App;